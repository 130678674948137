import logo from './shield_pixel.png';
import hourglass from './hourglass_icon.png';
import './App.css';
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

function App() {
  const [textInput, setTextInput] = useState('');
  const [apiResponse, setApiResponse] = useState('');
  const [virusTotalApiResponse, setVirusTotalApiResponse] = useState('');
  const [builtWithApiResponse, setBuiltWithApiResponse] = useState('');
  const [presignedUrl, setPresignedUrl] = useState('');
  const [presignedUrl2, setPresignedUrl2] = useState('');
  const [presignedUrl3, setPresignedUrl3] = useState('');
  const [isLoadingText, setIsLoadingText] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [textError, setTextError] = useState('');
  const [imageError, setImageError] = useState('');
  const [isImageExpanded, setIsImageExpanded] = useState(false);
  const [isVirusTotalExpanded, setIsVirusTotalExpanded] = useState(false); 
  const [isBuiltWithExpanded, setIsBuiltWithExpanded] = useState(false); 
  const [showResults, setShowResults] = useState(false);
  const textRef = useRef(null);

  const sanitizedInput = encodeURIComponent(textInput.replace(/^(https?:\/\/)/i, ''));
  
  var imageS3PresignedUrlParameterVirusTotal;
  var imageS3PresignedUrlParameterBuiltWith;

  const handleClick = async () => {
    setIsLoadingText(true);
    setIsLoadingImage(true);
    setTextError('');
    setImageError('');
    setShowResults(true);
    
    try {
      const imageResponse = await axios.get(`https://vijxc4izs8.execute-api.us-east-1.amazonaws.com/dev/url-content-screenshot?url=${sanitizedInput}`);
      setPresignedUrl(imageResponse.data.presignedUrl);
    } catch (error) {
      console.error(error);
      setImageError('Error fetching website image');
    } finally {
      setIsLoadingImage(false);
    }
    
    try {
      const builtWithResponse = await axios.get(`https://vijxc4izs8.execute-api.us-east-1.amazonaws.com/dev/url-builtwith-analysis?url=${sanitizedInput}`);
      setPresignedUrl3(builtWithResponse.data.presignedUrl);
      imageS3PresignedUrlParameterBuiltWith = encodeURIComponent(builtWithResponse.data.presignedUrl);
    } catch (error) {
      console.error(error);
      setImageError('Error fetching BuiltWith image');
    } finally {
      setIsLoadingImage(false);
    }
    
    try {
      const virusTotalResponse = await axios.get(`https://vijxc4izs8.execute-api.us-east-1.amazonaws.com/dev/url-virustotal-analysis?url=${sanitizedInput}`);
      setPresignedUrl2(virusTotalResponse.data.presignedUrl);
      imageS3PresignedUrlParameterVirusTotal = encodeURIComponent(virusTotalResponse.data.presignedUrl);
    } catch (error) {
      console.error(error);
      setImageError('Error fetching VirusTotal image');
    } finally {
      setIsLoadingImage(false);
    }

    try {
      const response = await axios.get(`https://vijxc4izs8.execute-api.us-east-1.amazonaws.com/dev/url-content-text-analysis?prompt=${sanitizedInput}`);
      const outputText = response.data || '';
      setApiResponse(outputText);
    } catch (error) {
      console.error(error);
      setTextError('Error fetching text analysis');
    } finally {
      setIsLoadingText(false);
    }
    
    try {
      const response = await axios.get(`https://vijxc4izs8.execute-api.us-east-1.amazonaws.com/dev/url-content-source-and-image-analysis?analysisSource=virustotal.com&imageS3PresignedUrl=${imageS3PresignedUrlParameterVirusTotal}`);
      const outputText2 = response.data || '';
      setVirusTotalApiResponse(outputText2);
      setVirusTotalApiResponse(prevState => outputText2);
    } catch (error) {
      console.error(error);
      setTextError('Error fetching Virus Total analysis');
    } finally {
      setIsLoadingText(false);
    }
    
    try {
      const response = await axios.get(`https://vijxc4izs8.execute-api.us-east-1.amazonaws.com/dev/url-content-source-and-image-analysis?analysisSource=builtwith.com&imageS3PresignedUrl=${imageS3PresignedUrlParameterBuiltWith}`);
      const outputText3 = response.data || '';
      setBuiltWithApiResponse(outputText3);
      setBuiltWithApiResponse(prevState => outputText3);
    } catch (error) {
      console.error(error);
      setTextError('Error fetching BuiltWith analysis');
    } finally {
      setIsLoadingText(false);
    }

  };

  const handleKeyDown = event => {
    if (event.keyCode === 13) { // Enter key
      handleClick();
    }
  };

  const toggleImageExpansion = () => {
    setIsImageExpanded(!isImageExpanded);
  };
  
  const toggleVirusTotalExpansion = () => {
    setIsVirusTotalExpanded(!isVirusTotalExpanded);
  };
  
  const toggleBuiltWithExpansion = () => {
    setIsBuiltWithExpanded(!isBuiltWithExpanded);
  };

  useEffect(() => {
    if (textRef.current) {
      const textElement = textRef.current;
      const imageElement = document.querySelector('.website-preview');
      if (imageElement) {
        const imageHeight = imageElement.offsetHeight;
        const columnHeight = window.innerHeight - 200; // Adjust the padding as needed
        const maxTextHeight = columnHeight - imageHeight - 40; // 40 is the margin between image and text
        textElement.style.maxHeight = `${maxTextHeight}px`;
      }
    }
  }, [presignedUrl, isImageExpanded, isVirusTotalExpanded, isBuiltWithExpanded]);

  return (
    <div className="App">
      <header className="App-header" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
        <img src={logo} className="App-logo" alt="logo" />
        <p style={{textAlign: 'center'}}>asanode <br /><code>Frontline<br /></code></p>
        <textarea value={textInput} style={{textAlign: 'center'}} onChange={(event) => setTextInput(event.target.value)} onKeyDown={handleKeyDown} rows="1" cols="30" placeholder="Enter link to be analyzed" />
        <br />
        <button onClick={handleClick}>Analyze link content</button>
        {(isLoadingText || isLoadingImage) && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '0px' }}><p>Loading...</p><img src={hourglass} alt="Loading..." width="50px" height="50px" /></div>}
      </header>
    
      {showResults && <div className="App-results" >
        <div className="App-results-no-border" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto' }}>
          {imageError && <p style={{ color: 'red' }}>{imageError}</p>}
          {presignedUrl && <div className="column" style={{ display: 'flex', justifyContent: 'center', marginTop: '0px',  alignItems: 'center' }}>
            <div className="column-no-border" onClick={toggleImageExpansion} style={{ cursor: 'pointer' }}><p className="App-text" style={{textAlign: 'center'}}>Screenshot</p><img src={presignedUrl} alt="Website Screenshot" className="website-preview" style={{ maxWidth: isImageExpanded ? `${window.innerWidth * 0.8}px` : '200px', height: isImageExpanded ? 'auto' : '200px' }} /></div>
            {textError && <p style={{ color: 'red' }}>{textError}</p>}
            {apiResponse && <div className="App-text-with-border" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding:'10px', overflow: 'auto' }}><p ref={textRef} style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace', fontSize: '1.0em' }}>{apiResponse}</p></div>}
          </div>}
        </div>
        <div className="App-results-no-border" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto' }}>
          {imageError && <p style={{ color: 'red' }}>{imageError}</p>}
          {presignedUrl3 && <div className="column" style={{ display: 'flex', justifyContent: 'center', marginTop: '0px',  alignItems: 'center' }}>
            <div className="column-no-border" onClick={toggleBuiltWithExpansion} style={{ cursor: 'pointer' }}><p className="App-text" style={{textAlign: 'center'}}>BuiltWith</p><img src={presignedUrl3} alt="BuiltWith Screenshot" className="website-preview" style={{ maxWidth: isBuiltWithExpanded ? `${window.innerWidth * 0.8}px` : '200px', height: isBuiltWithExpanded ? 'auto' : '200px' }} /></div>
            {textError && <p style={{ color: 'red' }}>{textError}</p>}
            {builtWithApiResponse && <div className="App-text-with-border" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding:'10px', overflow: 'auto' }}><p ref={textRef} style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace', fontSize: '1.0em' }}>{builtWithApiResponse}</p></div>}
          </div>}
        </div>
        <div className="App-results-no-border" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'auto' }}>
          {imageError && <p style={{ color: 'red' }}>{imageError}</p>}
          {presignedUrl2 && <div className="column" style={{ display: 'flex', justifyContent: 'center', marginTop: '0px',  alignItems: 'center' }}> 
            <div className="column-no-border" onClick={toggleVirusTotalExpansion} style={{ cursor: 'pointer' }}><p className="App-text" style={{textAlign: 'center'}}>VirusTotal</p><img src={presignedUrl2} alt="VirusTotal Screenshot" className="website-preview" style={{ maxWidth: isVirusTotalExpanded ? `${window.innerWidth * 0.8}px` : '200px', height: isVirusTotalExpanded ? 'auto' : '200px' }} /></div>
            {textError && <p style={{ color: 'red' }}>{textError}</p>}
            {virusTotalApiResponse && <div className="App-text-with-border" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding:'10px', overflow: 'auto' }}><p ref={textRef} style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace', fontSize: '1.0em' }}>{virusTotalApiResponse}</p></div>}
          </div>}
        </div>
      </div>}
    
      <div style={{ backgroundColor: 'black', color: 'white', marginTop: '2px', textAlign: 'center', padding: '2px', fontSize: '0.8em' }}>This app is for demonstration purposes only. Users are responsible for the outputs they generate. <br />Model: txt2txt-anthropic-claude-v3-sonnet. Generated content may be inaccurate or false.</div>
    </div>
  );
}

export default App;